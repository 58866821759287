import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router'

import { UserStore } from '@/stores/User'
import { handleAsync as fn } from '@/utils/errorHandler'
import { NotifyStore } from '@/stores/Notify'
import UserController from '@/controllers/user.controller'
import ConstantController from '@/controllers/constant.controller'

const allRoutes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Inicio ',
    meta: {
      access: ['ALL']
    },
    component: () => import('@/views/teacher/HomeView.vue')
  },
  {
    path: '/profile',
    name: 'Perfil',
    meta: {
      access: ['ALL']
    },
    component: () => import('@/views/teacher/ProfileView.vue')
  },
  {
    name: 'Auth',
    path: '/auth',
    meta: {
      access: ['ALL']
    },
    component: () => import('@/views/AuthView.vue'),
    children: [
      {
        name: 'Inicia Sesión',
        path: 'login',
        meta: {
          access: ['ALL'],
          title: 'Inicia Sesión',
          description: 'Inicia sesión para acceder a la plataforma'
        },
        component: () => import('@/views/auth/LoginView.vue')
      },
      {
        name: 'Registrate',
        path: 'register',
        meta: {
          access: ['ALL'],
          title: 'Registrate',
          description: 'Registrate para acceder a la plataforma'
        },
        component: () => import('@/views/auth/RegisterView.vue')
      },
      {
        name: 'Docente Nuevo',
        path: 'bienvenido',
        meta: {
          access: ['ALL']
        },
        component: () => import('@/views/auth/BienvenidoView.vue')
      },
      {
        name: 'Ingresa',
        path: 'dni',
        meta: {
          access: ['ALL'],
          title: 'Ingresa',
          description: 'Inicia sesión con tu DNI'
        },
        component: () => import('@/views/auth/DniView.vue')
      },
      {
        name: 'Recupera tu cuenta',
        path: 'recover',
        meta: {
          access: ['ALL'],
          title: 'Recupera tu cuenta',
          description: 'Te enviaremos un correo para recuperar tu cuenta'
        },
        component: () => import('@/views/auth/RecoverView.vue')
      }
    ]
  },
  {
    name: 'Realiza tu examen',
    path: '/test/:id',
    meta: {
      access: ['ALL']
    },
    props: true,
    component: () => import('@/views/teacher/TestView.vue')
  },
  {
    name: 'Resultados de tu examen',
    path: '/test-result/:id',
    meta: {
      access: ['ALL']
    },
    props: true,
    component: () => import('@/views/teacher/TestResultView.vue')
  },
  {
    name: 'Público',
    path: '/public',
    meta: {
      access: ['ALL']
    },
    children: [
      {
        name: 'Registro Personalizado',
        path: 'custom-register',
        meta: {
          access: ['ALL']
        },
        component: () => import('@/views/public/CustomRegister.vue')
      },
      {
        name: 'Formulario',
        path: 'form/:id',
        meta: {
          access: ['ALL']
        },
        props: true,
        component: () => import('@/views/public/FormView.vue')
      },
      {
        name: 'Formulario Completado',
        path: 'success',
        meta: {
          access: ['ALL']
        },
        props: true,
        component: () => import('@/views/public/FormSuccessView.vue')
      },
      {
        name: 'Formulario Completado',
        path: 'success/:id',
        meta: {
          access: ['ALL']
        },
        props: true,
        component: () => import('@/views/public/FormSuccessView.vue')
      }
    ]
  },
  {
    name: 'Admin',
    path: '/admin',
    meta: {
      access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'ASESOR', 'PONENTE', 'ATC']
    },
    component: () => import('@/views/AdminView.vue'),
    children: [
      {
        name: 'Dashboard',
        path: 'dashboard',
        meta: {
          access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'ASESOR', 'PONENTE', 'ATC'],
          icon: 'fa-regular fa-user-gear'
        },
        children: [
          {
            name: '¡Bienvenido !',
            path: '',
            meta: {
              access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'ASESOR', 'PONENTE', 'ATC'],
              hidden: true,
              icon: 'fa-regular fa-home'
            },
            component: () => import('@/views/admin/WelcomeView.vue')
          },
          {
            name: 'Ir a Simulacros',
            path: 'go-to-exams',
            redirect: '/',
            meta: {
              access: ['PONENTE'],
              icon: 'fa-regular fa-home'
            }
          },
          {
            name: 'Inicio',
            path: 'home',
            meta: {
              access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'ASESOR', 'ATC'],
              icon: 'fa-regular fa-home'
            },
            component: () => import('@/views/admin/advisers/HomeView.vue')
          },
          {
            name: 'Tus Links',
            path: 'links',
            meta: {
              access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'ASESOR', 'ATC'],
              icon: 'fa-regular fa-link'
            },
            component: () => import('@/views/admin/advisers/LinkView.vue')
          },
          {
            name: 'Tus Ventas',
            path: 'sells',
            meta: {
              access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'ASESOR', 'ATC'],
              icon: 'fa-regular fa-receipt'
            },
            component: () => import('@/views/admin/advisers/SellsView.vue')
          },
          {
            name: 'Tus Pagos',
            path: 'pays',
            meta: {
              access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'ASESOR', 'ATC'],
              icon: 'fa-regular fa-hand-holding-dollar'
            },
            component: () => import('@/views/admin/advisers/PaysView.vue')
          },
          {
            name: 'Crear Venta',
            path: 'create-sell',
            meta: {
              access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'ASESOR', 'ATC'],
              icon: 'fa-regular fa-hand-holding-dollar'
            },
            component: () => import('@/views/admin/advisers/CreateSell.vue')
          },
          {
            name: 'Reportar Número',
            path: 'report-number',
            props: true,
            meta: {
              access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'ASESOR', 'ATC'],
              icon: 'fa-regular fa-ban'
            },
            component: () => import('@/views/admin/advisers/ReportNumber.vue')
          },
          {
            name: 'Editar Pago',
            path: 'pay/:id',
            props: true,
            meta: {
              access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'ASESOR', 'ATC'],
              hidden: true,
              icon: 'fa-regular fa-lock'
            },
            component: () => import('@/views/admin/advisers/EditPay.vue')
          },
          {
            name: 'Editar Venta',
            path: 'sell/:id',
            props: true,
            meta: {
              access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'ASESOR', 'ATC'],
              hidden: true,
              icon: 'fa-regular fa-lock'
            },
            component: () => import('@/views/admin/advisers/EditSell.vue')
          }
        ]
      },
      {
        name: 'Globales',
        path: 'globals',
        meta: {
          access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'ATC'],
          icon: 'fa-regular fa-shield-halved'
        },
        children: [
          {
            name: '¡Bienvenido!',
            path: '',
            meta: {
              access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'ATC'],
              hidden: true,
              icon: 'fa-regular fa-home'
            },
            component: () => import('@/views/admin/WelcomeView.vue')
          },
          {
            name: 'Constantes',
            path: 'constants',
            meta: {
              access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR'],
              icon: 'fa-regular fa-input-numeric'
            },
            children: [
              {
                name: 'Lista de constantes',
                path: 'list',
                meta: {
                  access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR'],
                  title: 'Lista',
                  icon: 'fa-regular fa-list'
                },
                component: () => import('@/views/admin/globals/constants/ListView.vue')
              },
              {
                name: 'Crear nueva constante',
                path: 'create',
                meta: {
                  access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR'],
                  title: 'Crear',
                  icon: 'fa-regular fa-plus'
                },
                component: () => import('@/views/admin/globals/constants/CreateView.vue')
              },
              {
                name: 'Editar constante',
                props: true,
                path: 'edit/:id',
                meta: {
                  access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR'],
                  title: 'Editar',
                  icon: 'fa-regular fa-pencil-ruler'
                },
                component: () => import('@/views/admin/globals/constants/EditView.vue')
              }
            ]
          },
          {
            name: 'Usuarios',
            path: 'users',
            meta: {
              access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'ATC'],
              icon: 'fa-regular fa-user-crown'
            },
            children: [
              {
                name: 'Lista de usuarios',
                path: 'list',
                meta: {
                  access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'ATC'],
                  title: 'Lista',
                  icon: 'fa-regular fa-list'
                },
                component: () => import('@/views/admin/globals/users/ListView.vue')
              },
              {
                name: 'Crear nuevo usuario',
                path: 'create',
                meta: {
                  access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR'],
                  title: 'Crear',
                  icon: 'fa-regular fa-plus'
                },
                component: () => import('@/views/admin/globals/users/CreateView.vue')
              },
              {
                name: 'Editar usuario',
                props: true,
                path: 'edit/:id',
                meta: {
                  access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'ATC'],
                  title: 'Editar',
                  icon: 'fa-regular fa-pencil-ruler'
                },
                component: () => import('@/views/admin/globals/users/EditView.vue')
              }
            ]
          }
        ]
      },
      {
        name: 'Exámenes',
        path: 'exams',
        meta: {
          access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR'],
          icon: 'fa-regular fa-block-question'
        },
        redirect: '/admin/exams/tests',
        children: [
          {
            name: 'Tests',
            path: 'tests',
            // redirect: '/admin/exams/tests/create',
            meta: {
              access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR'],
              icon: 'fa-regular fa-block-question'
            },
            children: [
              {
                name: 'Lista de tests',
                path: 'list',
                meta: {
                  access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR'],
                  title: 'Lista',
                  icon: 'fa-regular fa-list'
                },
                component: () => import('@/views/admin/exams/tests/ListView.vue')
              },
              {
                name: 'Crear nuevo test',
                path: 'create',
                meta: {
                  access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR'],
                  title: 'Crear',
                  icon: 'fa-regular fa-plus'
                },
                component: () => import('@/views/admin/exams/tests/CreateView.vue')
              },
              {
                name: 'Editar test',
                props: true,
                path: 'edit/:id',
                meta: {
                  access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR'],
                  title: 'Editar',
                  icon: 'fa-regular fa-pencil-ruler'
                },
                component: () => import('@/views/admin/exams/tests/EditView.vue')
              }
            ]
          },
          {
            name: 'Preguntas',
            path: 'questions',
            meta: {
              access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR'],
              icon: 'fa-regular fa-block-question'
            },
            children: [
              {
                name: 'Lista de preguntas',
                path: 'list',
                meta: {
                  access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR'],
                  title: 'Lista',
                  icon: 'fa-regular fa-list'
                },
                component: () => import('@/views/admin/exams/questions/ListView.vue')
              },
              {
                name: 'Crear nueva pregunta',
                path: 'create',
                meta: {
                  access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR'],
                  title: 'Crear',
                  icon: 'fa-regular fa-plus'
                },
                component: () => import('@/views/admin/exams/questions/CreateView.vue')
              },
              {
                name: 'Editar una  pregunta',
                path: 'edit/:id',
                props: true,
                meta: {
                  access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR'],
                  title: 'Editar',
                  icon: 'fa-regular fa-pen'
                },
                component: () => import('@/views/admin/exams/questions/EditView.vue')
              },
              {
                name: 'Metricas Docente',
                path: 'metric',
                props: true,
                meta: {
                  access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR'],
                  icon: 'fa-solid fa-chart-scatter'
                },
                component: () => import('@/views/admin/advisers/MetricsView.vue')
              },
              {
                name: 'Copies',
                path: 'copy',
                props: true,
                meta: {
                  access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR'],
                  icon: 'fa-sharp-duotone fa-solid fa-copy'
                },
                component: () => import('@/views/admin/advisers/CopyView.vue')
              }
            ]
          }
        ]
      },
      {
        name: 'Financiero',
        path: 'finance',
        meta: {
          access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR'],
          icon: 'fa-regular fa-coins'
        },
        redirect: '/admin/finance/products',
        children: [
          {
            name: 'Productos',
            path: 'products',
            meta: {
              access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR'],
              icon: 'fa-regular fa-boxes-stacked'
            },
            children: [
              {
                name: 'Crear nuevo producto',
                path: 'create',
                meta: {
                  access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR'],
                  title: 'Crear',
                  icon: 'fa-regular fa-plus'
                },
                component: () => import('@/views/admin/finance/products/CreateView.vue')
              },
              {
                name: 'Lista de productos',
                path: 'list',
                meta: {
                  access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR'],
                  title: 'Lista',
                  icon: 'fa-regular fa-list'
                },
                component: () => import('@/views/admin/finance/products/ListView.vue')
              },
              {
                name: 'Editar producto',
                path: 'edit/:id',
                props: true,
                meta: {
                  access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR'],
                  title: 'Editar',
                  icon: 'fa-regular fa-pencil-ruler'
                },
                component: () => import('@/views/admin/finance/products/EditView.vue')
              }
            ]
          },
          {
            name: 'Ventas',
            path: 'sells',
            meta: {
              access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR'],
              icon: 'fa-regular fa-cash-register'
            },
            children: [
              {
                name: 'Crear nueva venta',
                path: 'create',
                meta: {
                  access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR'],
                  title: 'Crear',
                  icon: 'fa-regular fa-plus'
                },
                component: () => import('@/views/admin/finance/sells/CreateView.vue')
              },
              {
                name: 'Lista de ventas',
                path: 'list',
                meta: {
                  access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR'],
                  title: 'Lista',
                  icon: 'fa-regular fa-list'
                },
                component: () => import('@/views/admin/finance/sells/ListView.vue')
              }
            ]
          },
          {
            name: 'Pagos',
            path: 'pays',
            // redirect: '/admin/finance/pays/create',
            meta: {
              access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR'],
              icon: 'fa-regular fa-receipt'
            },
            children: [
              {
                name: 'Crear nueva pago',
                path: 'create',
                meta: {
                  access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR'],
                  title: 'Crear',
                  icon: 'fa-regular fa-plus'
                },
                component: () => import('@/views/admin/finance/pays/CreateView.vue')
              },
              {
                name: 'Lista de pagos',
                path: 'list',
                meta: {
                  access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR'],
                  title: 'Lista',
                  icon: 'fa-regular fa-list'
                },
                component: () => import('@/views/admin/finance/pays/ListView.vue')
              }
            ]
          }
        ]
      },
      {
        name: 'Formularios',
        path: 'forms',
        meta: {
          access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR'],
          icon: 'fa-regular fa-clipboard-question'
        },
        redirect: '/admin/forms/create',
        children: [
          {
            name: 'Crear Formulario',
            path: 'create',
            meta: {
              access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR'],
              icon: 'fa-regular fa-plus'
            },
            component: () => import('@/views/admin/forms/CreateView.vue')
          },
          {
            name: 'Lista de Formularios',
            path: 'list',
            meta: {
              access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR'],
              icon: 'fa-regular fa-rectangle-vertical-history'
            },
            component: () => import('@/views/admin/forms/ListView.vue')
          },
          {
            name: 'Resultados de formulario',
            path: 'results/:formId',
            props: true,
            meta: {
              access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR'],
              icon: 'fa-regular fa-list-check'
            },
            component: () => import('@/views/admin/forms/ResultsView.vue')
          },
          {
            name: 'Editar Formulario',
            path: 'edit/:id',
            props: true,
            meta: {
              access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR'],
              icon: 'fa-regular fa-pencil-ruler'
            },
            component: () => import('@/views/admin/forms/EditView.vue')
          }
        ]
      },
      {
        name: 'Reportería',
        path: 'reports',
        meta: {
          access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'PONENTE'],
          icon: 'fa-regular fa-file-chart-column'
        },
        children: [
          {
            name: 'Bienvenido!',
            path: '',
            meta: {
              access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'PONENTE'],
              hidden: true,
              icon: 'fa-regular fa-home'
            },
            component: () => import('@/views/admin/WelcomeView.vue')
          },
          {
            name: 'Pagos',
            path: 'r0002/:meta?',
            props: true,
            meta: {
              access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR'],
              icon: 'fa-regular fa-file-chart-column',
              label: 'LOGISTICA'
            },
            component: () => import('@/views/reports/R0002.vue')
          },
          {
            name: 'Simulacros',
            path: 'r0003/:meta?',
            props: true,
            meta: {
              access: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'PONENTE'],
              icon: 'fa-regular fa-file-chart-column',
              label: 'C. ACADEM'
            },
            component: () => import('@/views/reports/R0003.vue')
          }
        ]
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes: allRoutes
})

router.beforeEach(async (to, from, next) => {
  if (to.fullPath === from.fullPath) return next()
  if (to.fullPath === '/auth/login') return next()
  console.group(`Navigation to ${to.fullPath}`)
  console.log(`Navigating from ${from.fullPath} to ${to.fullPath}`)
  document.title = `GDP - ${String(to.name)}`

  const toAccess: string[] = (to.meta.access as string[]) || []
  if (toAccess.includes('ALL')) {
    console.log('🔒 Access granted: Open access')
    console.groupEnd()
    return next()
  }

  const USER_STORE = UserStore()
  const NOTIFY = NotifyStore()
  const token = USER_STORE.token

  if (toAccess.includes('AUTH')) {
    if (!token || token.trim() === '') {
      console.log('🔒 Access denied: Requires login')
      console.groupEnd()
      return next({ path: '/auth/login' })
    }
    console.log('🔒 Access granted: Authenticated access')
    console.groupEnd()
    return next()
  }

  const [err, data] = await fn(() => UserController.checkJwt(token))
  if (err || !data) {
    console.log('🔒 Access denied: Invalid JWT')
    console.groupEnd()
    return next({ path: '/auth/login' })
  }

  const roleId = data.data.role
  const [err2, data2] = await fn(() => ConstantController.get(roleId, [], ['-_id', 'code']))
  if (err2 || !data2) {
    console.log('🔒 Access denied: Invalid role')
    console.groupEnd()
    return next({ path: '/auth/login' })
  }

  const role = data2.item.code
  console.log(`User role: ${role}`)

  if (toAccess.includes(role) || role === 'SUPER_ADMIN') {
    console.log(`🔒 Access granted: Role ${role}`)
    console.groupEnd()
    return next()
  } else {
    console.log(`🔒 Access denied: Role ${role}`)
    NOTIFY.error('No tienes permisos para acceder a esta página')
    console.groupEnd()
    return next({ path: '/' })
  }
})

export default router
